import React from "react"
import {Helmet} from "react-helmet"
import Layout from "../../../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import "../../../styles/styles.scss"
import providerStyles from "./provider.module.scss"
import Logo from "../../../components/logo"
import "intersection-observer"
import leftCorner from "../../../images/decorative-corner-left.svg"

const ProviderPage = () => (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pl" />
        <title>Lista Dostawców | Pensjonat U Wierzby Pod Lasem</title>
        <meta name="description" content="Lista dostawców strony Pensjonatu U Wierzby Pod Lasem."/>
        <link rel="canonical" href="https://www.uwierzby.pl/polityka-prywatnosci/lista-dostawcow/" />
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link rel="preload" href="https://fonts.googleapis.com/css2?family=Charm&family=Cormorant+SC:wght@300&family=Quicksand&display=swap" as="style"></link>
      </Helmet>
      <Layout>
        <section className={`JumbotronSection ${providerStyles.JumbotronSection___provider}`}>
          <Jumbotron fluid className={`Jumbotron`}>
            <div className={`Jumbotron_overlay`}></div>
            <div className={`Jumbotron_logo`}>
              <Logo/>
            </div>
            <Container fluid className={`Jumbotron_container`}>
              <h1>dostawcy</h1>
              <h3 className={`SectionSubTitleH3 light`}>lista dostawców</h3>
            </Container>
          </Jumbotron>
        </section>
        <section className={`BreadcrumbSection`}>
          <Breadcrumb className={`Breadcrumb`}>
              <Breadcrumb.Item className={`Breadcrumb_item`} href="../../">Strona Główna</Breadcrumb.Item>
              <Breadcrumb.Item className={`Breadcrumb_item`} href="../">Polityka Prywatności</Breadcrumb.Item>
              <Breadcrumb.Item className={`Breadcrumb_item`} active>Lista Dostawców</Breadcrumb.Item>
          </Breadcrumb>
        </section>
        <section className={`MainSection`}>
          <Container fluid className={`MainSection_wrapper`}>
            <img className={`MainSection_corner___left`} src={leftCorner} alt="top left decorative corner"></img>
            <div className={`MainSection_textWrapper`}>
              <div className={`SectionTitle_wrapper`}>
                <div className={`SectionTitle_bigLetter`}><p>D</p></div>
                <h1 className={`SectionTitle`}>dostawcy</h1>
                <h3 className={`SectionSubTitleH3`}>lista dostawców</h3>
              </div>
              <div className={`MainSection_main gallery`}>
                <div className={`MainSection_mainText ${providerStyles.providersGrid}`}>
                  <div>Nazwa Spółki</div>
                  <div>Świadczone Usługi</div>
                  <div>Rola</div>
                  <div>Lokalizacja</div>
                  <div>Google Inc. (Google Maps)</div>
                  <div>Wyświetlanie mapy dojazdu do firmy</div>
                  <div>Administrator</div>
                  <div>USA (poza EOG)</div>
                  <div>Wirtualna Polska Media S.A</div>
                  <div>Obsługa zapytań mailowych</div>
                  <div>Podmiot przetwarzacjący</div>
                  <div>Polska (EOG)</div>
                  <div>Hexcom Networks Sp. z o.o.</div>
                  <div>Usługi hostingowe – utrzymanie strony na serwerze</div>
                  <div>Podmiot przetwarzacjący</div>
                  <div>Polska (EOG)</div>
                  <div>Everes Studio (AMES Anna Stachowicz)</div>
                  <div>Usługa administrowania stroną Pensjonatu</div>
                  <div>-</div>
                  <div>Polska (EOG)</div>
                </div>
              </div>
            </div>
        </Container>
      </section>
    </Layout>
  </>
);

export default ProviderPage
